import utils from '../../utils/index'

const state = {
  bottomId: 0, //底部tab的切换
  bottomShow: true
}
const getters = {
  bottomId: state => state.bottomId,
  bottomShow: state => state.bottomShow
}
const mutations = {
  bottomId: (state, bottomId) => {
    utils.setSessionStorage('bottomId', bottomId)
    state.bottomId = bottomId;
  },
  bottomShow: (state, bottomShow) => {

    state.bottomShow = bottomShow;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}