import request from '@/utils/request'
export default {

  //购物车列表
  getCartList(data) {
    return request({
      url: '/wx/cart/index',
      method: 'GET',
      params: data
    })
  },
  //加入购物车
  addCart(data) {
    return request({
      url: '/wx/cart/add',
      method: 'POST',
      data
    })
  },
  // /购物车商品货品勾选状态 
  checkCard(data) {
    return request({
      url: '/wx/cart/checked',
      method: 'POST',
      data
    })
  },
  //修改购物车商品货品数量 
  updateCardNum(data) {
    return request({
      url: '/wx/cart/update',
      method: 'POST',
      data
    })
  },
  //删除购物车
  delCard(data) {
    return request({
      url: '/wx/cart/delete',
      method: 'POST',
      data
    })
  },
  ///购物车结算
  checkoutCart(data) {
    return request({
      url: '/wx/cart/checkout',
      method: 'GET',
      params: data
    })
  },
  //确认兑换
  orderSubmit(data) {
    return request({
      url: '/wx/order/submit',
      method: 'POST',
      data
    })
  },
  //E卡商品详情立即兑换
  submitNoAddr(data) {
    return request({
      url: '/wx/order/fast/submitNoAddr',
      method: 'POST',
      data
    })
  },
  //商品详情立即兑换 
  orderFastSubmit(data) {
    return request({
      url: '/wx/order/fast/submit',
      method: 'POST',
      data
    })
  },
  //发送提交订单的短信验证码  
  postOrderSmsSend(data) {
    return request({
      url: '/wx/order/sms/send',
      method: 'POST',
      data
    })
  },
  // 兑换支付 
  postMobilePay(data) {
    return request({
      url: '/wx/order/mobile/pay',
      method: 'POST',
      data
    })
  },
  //核销订单
  postFastRsync(data) {
    return request({
      url: '/wx/order/fast/rsync',
      method: 'POST',
      data
    })
  }
}