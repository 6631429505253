<template>
  <div id="app">
    <keep-alive :include="cachedViews">
      <router-view
        :key="key"
        v-if="isRouterAlive"
      />
    </keep-alive>
  </div>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
  },
  created() {
    if (this._isMobile()) {
      // require("amfe-flexible/index.js");
      // this.$router.replace('/m_index');
    } else {
      // this.$router.replace('/pc_index');
      require("./utils/rem");
      require("./styles/pcIndex.scss");
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  position: relative;
}
// @media only screen and (min-width: 880px)
// @media only screen and(min-width: 1080px) {
//   html {
//     width: 750px !important;
//     margin: 0 auto !important;
//     font-size: 60px !important;
//   }

//   .bottom-menu,
//   .home-header {
//     width: 750px !important;
//     margin: 0 auto !important;
//   }
// }
// @media only screen and (min-width: 880px)
// @media only screen and(min-width: 480px) {
//   html {
//     width: 750px !important;
//     margin: 0 auto !important;
//     font-size: 60px !important;
//   }

//   .bottom-menu,
//   .home-header {
//     width: 750px !important;
//     margin: 0 auto !important;
//   }
// }
</style>
