import request from '@/utils/request'
export default {
  //订单详情  /wx/order/me/and/childuser/list
  postOrderDetailList(data) {
    return request({
      url: '/wx/order/me/and/childuser/list',
      method: 'POST',
      data
    })
  },
  //订单统计 
  getOrderStatisics(data) {
    return request({
      url: '/wx/order/statistics/list',
      method: 'GET',
      params: data
    })
  },
  //收支明细列表 
  getFlowList(data) {
    return request({
      url: '/wx/amount/flow/statistics/list',
      method: 'GET',
      params: data
    })
  },
  //号卡明细
  postHKList(data) {
    return request({
      url: '/wx/order/me/and/childuser/hk/list',
      method: 'POST',
      data
    })
  }
}