import {
  baasRequest
} from '@/utils/excelRequest'
export default {
  exportOrderExport(data, name) {

    const url = '/wx/order/me/and/childuser/list'
    baasRequest(url, {
      data
    }, name)

  },
  exportFlowEXpotr(data, name) {
    const url = '/wx/amount/flow/statistics/list/export'
    baasRequest(url, {
      params: data
    }, name, 'get')
  },
  //号卡明细导出
  exportHKExport(data, name) {

    const url = '/wx/order/me/and/childuser/hk/list'
    baasRequest(url, {
      data
    }, name)

  },
}