// 按需全局引入 vant组件
// 按需全局引入 vant组件
import Vue from 'vue'
import {
  Field
} from 'vant'
import {
  Form
} from 'vant';
import {
  Picker
} from 'vant';
import {
  Popup
} from 'vant';
import {
  Button
} from 'vant';
import {
  Area
} from 'vant';
import {
  Toast
} from 'vant';
import {
  Checkbox,
  CheckboxGroup
} from 'vant';
import {
  Icon
} from 'vant';
import {
  Swipe,
  SwipeItem
} from 'vant';
import {
  Skeleton
} from 'vant';
import {
  Overlay
} from 'vant';
import {
  Dialog
} from 'vant';
import {
  Search
} from 'vant';
import {
  List
} from 'vant';
import {
  PullRefresh
} from 'vant';
import {
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from 'vant';
import {
  Sku
} from 'vant';
import {
  AddressList
} from 'vant';
import {
  AddressEdit
} from 'vant';
import {
  Empty
} from 'vant';
import {
  Stepper
} from 'vant';
import {
  ContactEdit
} from 'vant';
import {
  SubmitBar
} from 'vant';
import {
  SwipeCell
} from 'vant';
import {
  Image as VanImage
} from 'vant';
import {
  Cell,
  CellGroup
} from 'vant';
import {
  Tab,
  Tabs
} from 'vant';
import {
  Uploader
} from 'vant';
import {
  RadioGroup,
  Radio
} from 'vant';
import {
  DatetimePicker
} from 'vant';
import {
  Calendar
} from 'vant';
import {
  Switch
} from 'vant';
import {
  Col,
  Row
} from 'vant';
import {
  Popover
} from 'vant';
import {
  Card
} from 'vant';
import {
  Loading
} from 'vant';
import {
  Cascader
} from 'vant';

import {
  Collapse,
  CollapseItem
} from 'vant';


Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.use(Cascader);
Vue.use(Loading);
Vue.use(Card);

Vue.use(Popover);
Vue.use(Col);
Vue.use(Row);
Vue.use(Switch);
Vue.use(Calendar);
Vue.use(DatetimePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(VanImage);
Vue.use(SwipeCell);
Vue.use(SubmitBar);
Vue.use(ContactEdit);
Vue.use(Stepper);
Vue.use(Empty);
Vue.use(AddressEdit);
Vue.use(AddressList);
Vue.use(Sku);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Search);
Vue.use(Dialog);

Vue.use(Overlay);
Vue.use(Skeleton);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast);
Vue.use(Area);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Form);
Vue.use(Field)
Vue.use(CheckboxGroup);
Vue.use(Checkbox)
Vue.use(Icon);