import request from '@/utils/request'
import Qs from 'qs'
export default {

  //添加地址
  setAddressAdd(data) {
    return request({
      url: '/jzshop/address/add',
      method: 'POST',
      data
    })
  },
  //获取联想地址
  setAddressQuery(data) {
    return request({
      url: 'wx/address/query',
      method: 'GET',
      params: data
    })
  },


}