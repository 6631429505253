import request from '@/utils/request'
import Qs from 'qs'
export default {
  //  团队列表
  getTeamList(data) {
    return request({
      url: '/wx/team/list',
      method: 'GET',
      params: data
    })
  },
  //添加账号 
  addTeanm(data) {
    return request({
      url: '/wx/team/add',
      method: 'POST',
      data
    })
  },
  //查看团队下面的代理的产品
  getTeamChildList(data) {
    return request({
      url: '/wx/team/childuser/product/list',
      method: 'GET',
      params: data
    })
  },
  //更新团队中指定的下级
  postTeamChildUpdata(data) {
    return request({
      url: '/wx/team/childuser/update',
      method: 'POST',
      data
    })
  },
  //禁用或者开启下级账号 
  getTeanmChildStatus(data) {
    return request({
      url: '/wx/team/childuser/status/update',
      method: 'GET',
      params: data
    })
  },
  //批量设置-查看我的所有产品
  getTeamProductList(data) {
    return request({
      url: '/wx/team/product/list',
      method: 'GET',
      params: data
    })
  },
  //批量更新团队中的所有下级折扣和状态
  postTeamChildBatchUpdata(data) {
    return request({
      url: '/wx/team/childusers/batch/update',
      method: 'POST',
      data
    })
  },
  //team/excel/add
  downTeamEcxcel(data) {
    console.log('kk', Qs.stringify(data))
    return request({
      url: '/wx/team/excel/add',
      method: 'POST',
      data
    })
  },
  //物流查询
  queryExpress(data) {
    return request({
      url: '/wx/order/queryExpress',
      method: 'POST',
      data: Qs.stringify(data)
    })
  }
}