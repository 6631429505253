 import request from '@/utils/request'
 export default {
   //兑换订单时，获取验证码orders/sendPaySms
   postSendPaySms(data) {
     return request({
       url: '/jzshop/orders/sendPaySms',
       method: 'POST',
       data
     })
   },
   //订单列表
   getOrderList(data) {
     return request({
       url: '/wx/order/list',
       method: 'GET',
       params: data
     })
   },
   //订单详情
   getOrderDetail(data) {
     return request({
       url: '/wx/order/detail',
       method: 'GET',
       params: data
     })
   },
   //确认兑换 
   postOrderPay(data) {
     return request({
       url: '/jzshop/orders/pay',
       method: 'POST',
       data
     })
   },
   //修改订单地址 
   postUpdateOrderAds(data) {
     return request({
       url: '/wx/order/address/update',
       method: 'POST',
       data
     })
   }
 }