import request from '@/utils/request'
import Qs from 'qs'
export default {

  //添加地址
  selectMobile(data) {
    return request({
      url: '/wx/address/selectMobile',
      method: 'POST',
      data: Qs.stringify(data)
    })
  },
  // /获取当前地址
  getAreaInfo(data) {
    return request({
      url: 'wx/auth/areaInfo',
      method: 'POST',
      data: Qs.stringify(data)
    })
  },
  //购物车发送短信
  getsendLink(data) {
    return request({
      url: 'wx/order/sms/sendLink',
      method: 'POST',
      data
    })
  },
  //购物车发送短信
  getqueryDeal(data) {
    return request({
      url: 'wx/order/fast/queryDeal',
      method: 'POST',
      data
    })
  }
}