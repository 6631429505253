import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {
  Toast
} from 'vant';
import {
  getToken
} from '@/utils/auth'
// 根据环境不同引入不同api地址
import baseUrl from '@/config'
// create an axios instancenpm np
const service = axios.create({
  baseUrl, // url = base api url + request url// url = base api url + request url
  // baseURL: 'http://192.168.0.125:8082
  withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    // 不传递默认开启loading
    // if (config.url === 'wx/address/query') {
    //   config.headers.Accept = "application/x-www-form-urlencoded"
    // }
    if (store.state.user.token) {
      config.headers['X-Litemall-Token'] = getToken()
    }

    // loading
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    return config
  },

  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    Toast.clear()
    const res = response.data

    //728：该商品存在待支付订单   去到订单页面
    if (res.errno && res.errno !== 0) {

      if (res.errno === 501) {
        //501：账号过期  
        Toast.fail(res.errmsg);
        setTimeout(() => {
          store.dispatch('user/logout').then(() => {
            // location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }, 3000)
      } else if (res.errno === 512) {
        Toast.fail(res.errmsg);
        // location.reload()

      } else if (res.errno === 507) {
        // 507：账号被禁用
        Toast.fail(res.errmsg);
        setTimeout(() => {
          store.dispatch('user/logout').then(() => {
            // location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }, 3000)
      } else if (res.errno === 508) {
        //508 商城手机号登录过期
        Toast.fail(res.errmsg);
        setTimeout(() => {
          store.dispatch('user/shopLogout').then(() => {

          })
        }, 3000)
      } else if (
        res.errno === 728 &&
        res.errmsg.indexOf("该商品存在待支付订单") !== -1
      ) {
        Toast(res.errmsg);
        setTimeout(() => {
          router.push({
            path: "/order",
            query: {
              active: "1",
            },
          });
        }, 2000);

      } else {

        // Toast.fail(res.errmsg)
        Toast({
          message: res.errmsg || '服务器失联',
          className: "totast-cum",
          duration: 3000
        });
      }
      return Promise.reject(res)
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    Toast.clear()
    Toast({
      message: "系统失联，请稍后！！!",
      className: "totast-cum toast-yellow",
      duration: 2000
    });
    return Promise.reject(error)
  }
)

export default service