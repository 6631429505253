import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {
  Toast
} from 'vant';
import {
  getToken
} from '@/utils/auth'
// 根据环境不同引入不同api地址
import baseUrl from '@/config'

// 下载xlsx

export function baasRequest(url, body, name, method = 'POST') {
  Toast.loading({
    message: '加载中...',
    forbidClick: true
  })

  axios({
      method: method,
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'X-Litemall-Token': getToken()
      },
      responseType: 'blob',
      ...body
    })
    .then(res => {
      // const clientName = '订单管理'
      // const response = res.data
      Toast.clear()
      // if (response.errno && response.errno !== 0) {
      //   Toast.fail(res.errmsg);

      // } else {
      const blob = new Blob([res.data])

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${name}.xlsx`)
      } else {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      // }

    })
    .catch(() => {
      Toast.clear()
      Toast({
        message: "系统失联，请稍后！！!",
        className: "totast-cum toast-yellow",
        duration: 2000
      });

    })
}