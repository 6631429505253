import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


Vue.use(VueRouter)

const routes = [


  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '/',

    component: () => import('../views/index'),
    children: [{ // 首页
      path: '/',
      name: 'home',
      component: () => import( /* webpackChunkName: "home" */ '../views/home'),
      meta: {
        title: "首页",
        noCache: true,
        keepAlive: false
      }
    },
    {
      path: '/mall',
      name: 'mall',
      component: () => import( /* webpackChunkName: "mall" */ '../views/mall'),
      meta: {
        title: "商城",
        noCache: false,
        keepAlive: true
      }
    },
    {

      path: '/shop',
      name: 'shop',
      component: () => import( /* webpackChunkName: "shop" */ '../views/shop'),
      meta: {
        title: "购物车",
        noCache: true,
        keepAlive: false
      }
    },
    { // 个人中心
      path: '/person',
      name: 'person',
      component: () => import( /* webpackChunkName: "person" */ '../views/person'),
      meta: {
        title: "个人中心",
        noCache: true,
        keepAlive: false
      }
    },
    {
      path: '/commodity',
      name: 'commodity',
      component: () => import( /* webpackChunkName: "commodity" */ '../views/commodity'),
      meta: {
        title: "商品详情",
        noCache: true
      }
    },
    ]
  },
  // {
  //   path: '/commodity',
  //   name: 'commodity',
  //   component: () => import( /* webpackChunkName: "commodity" */ '../views/commodity'),
  //   meta: {
  //     title: "商品详情",
  //     noCache: true
  //   }
  // },
  {
    path: '/logisticsCheck',
    name: 'logisticsCheck',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/logisticsCheck'),
    meta: {
      title: "物流查询",
      noCache: true
    }
  },
  {
    path: '/noticeDet',
    name: 'noticeDet',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/home/noticeDet/index'),
    meta: {
      title: "商城公告",
      noCache: true
    }
  },
  {
    path: '/delView',
    name: 'delView',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/home/noticeDet/delView'),
    meta: {
      title: "商城公告",
      noCache: true
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import( /* webpackChunkName: "order" */ '../views/order'),
    meta: {
      title: "订单详情",
      noCache: true
    }
  },
  {
    path: '/seach',
    name: 'seach',
    component: () => import( /* webpackChunkName: "seach" */ '../views/seach'),
    meta: {
      title: "搜索",
      noCache: true
    }
  },
  {
    path: '/applyEer',
    name: 'applyEer',
    component: () => import( /* webpackChunkName: "applyEer" */ '../views/applyEer'),
    meta: {
      title: "申请分销商",
      noCache: true
    }
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import( /* webpackChunkName: "exchange" */ '../views/exchange'),
    meta: {
      title: "兑换成功",
      noCache: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "register" */ '../views/register'),
    meta: {
      title: "注册",
      noCache: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "login" */ '../views/login'),
    meta: {
      title: "登录",
      noCache: true
    }
  },
  {
    path: '/address',
    name: 'address',
    component: () => import( /* webpackChunkName: "address" */ '../views/address'),
    meta: {
      title: "地址管理",
      noCache: true
    }
  },
  {
    path: '/revenue',
    name: 'revenue',
    component: () => import( /* webpackChunkName: "revenue" */ '../views/revenue'),
    meta: {
      title: "收支明细",
      noCache: true
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import( /* webpackChunkName: "withdraw" */ '../views/withdraw'),
    meta: {
      title: "立即提现",
      noCache: true
    }
  },
  {
    path: '/withdrawRecord',
    name: 'withdrawRecord',
    component: () => import( /* webpackChunkName: "withdrawRecord" */ '../views/withdrawRecord'),
    meta: {
      title: "提现记录",
      noCache: true
    }
  },
  {
    path: '/bankCard',
    name: 'bankCard',
    component: () => import( /* webpackChunkName: "bankCard" */ '../views/bankCard'),
    meta: {
      title: "银行卡",
      noCache: true
    }
  },
  {
    path: '/addBank',
    name: 'addBank',
    component: () => import( /* webpackChunkName: "addBank" */ '../views/addBank'),
    meta: {
      title: "绑定银行卡",
      noCache: true
    }
  },
  {
    path: '/orderSta',
    name: 'orderSta',
    component: () => import( /* webpackChunkName: "orderSta" */ '../views/orderSta'),
    meta: {
      title: "订单统计",
      noCache: true
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import( /* webpackChunkName: "orderDetail" */ '../views/orderDetail'),
    meta: {
      title: "订单详情",
      noCache: true
    }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import( /* webpackChunkName: "team" */ '../views/team'),
    meta: {
      title: "团队管理",
      noCache: true
    }
  },
  {
    path: '/teamDis',
    name: 'teamDis',
    component: () => import( /* webpackChunkName: "teamDis" */ '../views/teamDis'),
    meta: {
      title: "修改折扣",
      noCache: true
    }
  },
  {
    path: '/redeem',
    name: 'redeem',
    component: () => import( /* webpackChunkName: "redeem" */ '../views/redeem'),
    meta: {
      title: "积分兑换",
      noCache: true
    }
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import( /* webpackChunkName: "userCenter" */ '../views/userCenter'),
    meta: {
      title: "个人中心",
      noCache: true
    }
  },
  {
    path: '/changPwd',
    name: 'changPwd',
    component: () => import( /* webpackChunkName: "changPwd" */ '../views/changPwd'),
    meta: {
      title: "修改密码",
      noCache: true
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import( /* webpackChunkName: "pay" */ '../views/pay'),
    meta: {
      title: "收银台",
      noCache: true
    }
  },
  {
    path: '/withdrawSuc',
    name: 'withdrawSuc',
    component: () => import( /* webpackChunkName: "withdrawSuc" */ '../views/withdrawSuc'),
    meta: {
      title: "提现成功",
      noCache: true
    }
  },
  {
    path: '/addAccount',
    name: 'addAccount',
    component: () => import( /* webpackChunkName: "addAccount" */ '../views/addAccount'),
    meta: {
      title: "添加账号",
      noCache: true
    }
  },
  {
    path: '/myCommission',
    name: 'myCommission',
    component: () => import( /* webpackChunkName: "myCommission" */ '../views/myCommission'),
    meta: {
      title: "我的佣金",
      noCache: true
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import( /* webpackChunkName: "recharge" */ '../views/recharge'),
    meta: {
      title: "话费充值",
      noCache: true
    }
  }, {
    path: '/mobile',
    name: 'mobile',
    component: () => import( /* webpackChunkName: "mobile" */ '../views/mobile'),
    meta: {
      title: "移动商城",
      noCache: false
    }
  },
  {
    path: '/Ecard',
    name: 'Ecard',
    component: () => import( /* webpackChunkName: "mobile" */ '../views/Ecard'),
    meta: {
      title: "商城",
      noCache: false
    }
  },
  {
    path: '/MGselected',
    name: 'MGselected',
    component: () => import( /* webpackChunkName: "mobile" */ '../views/MGselected'),
    meta: {
      title: "咪咕",
      noCache: false
    }
  },
  {
    path: '/share',
    name: 'share',
    component: () => import( /* webpackChunkName: "share" */ '../views/share'),
    meta: {
      title: "推广码",
      noCache: false
    }
  },
  {
    path: '/exchangeCode',
    name: 'exchangeCode',
    component: () => import( /* webpackChunkName: "share" */ '../views/exchangeCode'),
    meta: {
      title: "积分商城",
      noCache: false
    }
  },
  {
    path: '/phoneCard',
    name: 'phoneCard',
    component: () => import( /* webpackChunkName: "phoneCard" */ '../views/phoneCard'),
    meta: {
      title: "号卡详情",
      noCache: true
    }
  },
  {
    path: '/cardDetail',
    name: 'cardDetail',
    component: () => import( /* webpackChunkName: "cardDetail" */ '../views/cardDetail'),
    meta: {
      title: "号卡中心",
      noCache: true
    }
  },
  {
    path: '/cardCut',
    name: 'cardCut',
    component: () => import( /* webpackChunkName: "cardCut" */ '../views/cardCut'),
    meta: {
      title: "号卡明细",
      noCache: true
    }
  },
  {
    path: '/cardAddBank',
    name: 'cardAddBank',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/cardAddBank'),
    meta: {
      title: "绑定支付宝",
      noCache: true
    }
  },
  {
    path: '/dxCard',
    name: 'dxCard',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/cardBusiness/dxCard'),
    meta: {
      title: "电销卡",
      noCache: true
    }
  },
  {
    path: '/dxPay',
    name: 'dxPay',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/cardBusiness/dxPay'),
    meta: {
      title: "电销卡支付",
      noCache: true
    }
  },
  {
    path: '/cyCard',
    name: 'cyCard',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/cardBusiness/cyCard'),
    meta: {
      title: "畅由号卡兑换",
      noCache: true
    }
  },
  {
    path: '/modelFlow',
    name: 'modelFlow',
    component: () => import( /* webpackChunkName: "cardAddBank" */ '../views/modelFlow'),
    meta: {
      title: "兑换流程",
      noCache: true
    }
  },
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit("menu/bottomShow", false);
  //修改头部网址描述
  document.title = '肥猫商城'
  if (to.name === 'cyCard') {
    document.title = '移动号卡'
  }
  if (to.name === 'home' || to.name === 'mall' || to.name === 'shop' || to.name == 'person') {


    let index = 0;
    if (to.name === "mall") {
      index = 1;
    } else if (to.name === "shop") {
      index = 2;
    } else if (to.name === "person") {
      index = 3;
    }
    store.commit("menu/bottomId", index);
    store.commit("menu/bottomShow", true);
  }


  if (to.query.icode) {
    next();
    return;
  };

  if (from.query.icode) {
    let icode = from.query.icode

    to.query.icode = icode
    next({
      ...to,
      query: to.query
    })

  } else {

    next()
  }

})

export default router