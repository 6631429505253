import utils from '../../utils/index'
//获取移动 文字验证后的 uniqueKey、captchaVerification
const state = {
  uniqueKey: '',
  captchaVerification: ''
}
const getters = {
  uniqueKey: state => state.uniqueKey,
  captchaVerification: state => state.captchaVerification
}
const mutations = {
  SET_UNIQUEKEY: (state, uniqueKey) => {
    console.log('sort', uniqueKey)
    utils.setSessionStorage('uniqueKey', uniqueKey)
    state.uniqueKey = uniqueKey;
    console.log('sort-uniqueKey', state.uniqueKey)
  },
  SET_captchaVer: (state, captchaVerification) => {
    utils.setSessionStorage('captchaVerification', captchaVerification)
    state.captchaVerification = captchaVerification;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}