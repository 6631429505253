import request from '@/utils/request'
export default {
  //获取列表
  getProductsList(data) {
    return request({
      url: '/wx/goods/list',
      method: 'GET',
      params: data
    })
  },
  //下架产品id
  getOffSaleGoods(data) {
    return request({
      url: '/wx/index/offSale',
      method: 'GET',
      params: data
    })
  },
  //商品分类
  getCatalog(data) {
    return request({
      url: '/wx/catalog/index',
      method: 'GET',
      params: data
    })
  },
  //商品详情 
  getProductsDetail(data) {
    return request({
      url: '/wx/goods/detail',
      method: 'GET',
      params: data
    })
  },
  //商城登录 wx/auth/login_by_phone
  shopLogin(data) {
    return request({
      url: '/wx/auth/login_by_phone',
      method: 'POST',
      data
    })
  },
  //渠道注销
  loginout(data) {
    return request({
      url: '/wx/auth/login_by_phone/loginout',
      method: 'POST',
      data
    })
  }

}