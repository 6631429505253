import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import menu from './modules/menu'
import user from './modules/user'
import tagsView from './modules/tagsView'
import verify from './modules/verify'
export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    user,
    menu,
    tagsView,
    verify
  }
})