import API from '../../api/http'
import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth'
import utils from '../../utils/index'
import jwt_decode from "jwt-decode"

const state = {
  token: getToken(), //token值
  points: utils.getStore('points') || 0, //积分值
  phone: utils.getStore('phone') || '', //手机号
  userInfo: '', //用户信息
  operator: '', //运营商
  attribution: '' //归属地
}

const getters = {
  isLogin: (state, getters) => {

    return getters.userType !== ''
  },
  isPhone() {
    if (state.token) {
      return state.phone
    }
    return ''
  },
  isPoints: state => {

    if (state.token) {
      return state.points || 0
    }
    return 0
  },
  userType: state => {
    //userLevel: 0 普通用户，1 1级代理，2 2级代理，3 3级代理，4 操作员

    if (state.token) {
      const decode = jwt_decode(state.token);

      return decode.userLevel
    }
    return ''
  },
  userInfos: state => {
    return state.userInfo === '' ?
      utils.getStore('userInfo') ?
        JSON.parse(utils.getStore('userInfo')) :
        '' :
      state.userInfo
  },
  userId: state => {
    if (state.token) {
      const decode = jwt_decode(state.token);
      return decode.userId
    }
    return ''
  },

}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_POINTS: (state, points) => {
    state.points = points
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_OPERATOR: (state, operator) => {
    state.operator = operator
  },
  SET_ATTRIBUTION: (state, attribution) => {
    state.attribution = attribution
  }
}

const actions = {
  // 我的 用户登录
  login({
    commit
  }, userInfo) {

    return new Promise((resolve, reject) => {
      API.postUserLogin(userInfo).then(response => {
        const {
          data
        } = response

        commit('SET_TOKEN', data.token)
        if (userInfo.type === 1) {
          commit('SET_POINTS', data.userInfo.amount)
          commit('SET_PHONE', userInfo.mobile)
          utils.setStore('phone', userInfo.mobile)
          utils.setStore('points', data.userInfo.amount)
        } else {
          commit('SET_POINTS', '')
          commit('SET_PHONE', '')
          utils.removeStore('phone')
          utils.removeStore('points')
        }
        commit('SET_USERINFO', data.userInfo)
        utils.setStore('userInfo', data.userInfo)

        setToken(data.token)
        resolve(data)
      }).catch(error => {

        reject(error)
      })
    })
  },
  //s商城登录
  shopLogin({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      API.shopLogin(userInfo).then(response => {
        const {
          data
        } = response

        commit('SET_TOKEN', data.token)
        commit('SET_POINTS', data.userInfo.amount)
        commit('SET_PHONE', userInfo.mobile)
        utils.setStore('phone', userInfo.mobile)
        utils.setStore('points', data.userInfo.amount)
        // commit('SET_USERINFO', data.userInfo)
        // utils.setStore('userInfo', data.userInfo)

        setToken(data.token)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取用户信息
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      API.postUserInfo().then(response => {
        const {
          data
        } = response
        if (!data) {
          reject('验证失败，请重新登录。')
        }
        utils.setStore('userInfo', data)
        commit('SET_USERINFO', data)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  // 用户注销
  logout({
    dispatch,
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      // API.logout().then(res=>{})
      console.log('logout')
      dispatch('resetToken')

    })
  },

  // 移除 token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {


      commit('SET_TOKEN', '')
      commit('SET_PHONE', '')
      commit('SET_USERINFO', '')
      commit('SET_POINTS', '')
      removeToken()
      utils.removeStore('phone')
      utils.removeStore('points')
      utils.removeStore('userInfo')
      utils.removeStore('bank')
      utils.removeStore('address')
      // utils.removeSessionStorage('bank')
      // utils.removeSessionStorage('area')
      resolve()
    })
  },
  //移动积分兑换的退出的退出
  shopLogout({
    commit
  }) {
    utils.removeStore('phone')
    utils.removeStore('points')

    commit('SET_PHONE', '')
    commit('SET_POINTS', '')
    commit('SET_OPERATOR', '')
    commit('SET_ATTRIBUTION', '')
    API.loginout().then((res) => {
      const {
        data
      } = res

      commit('SET_TOKEN', data.token)
      setToken(data.token)
      resolve(data)
    }).catch(err => {
      // reject(err)
      console.log(err)
    })

  },


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}