import request from '@/utils/request'
export default {
  //登录
  postUserLogin(data) {
    return request({
      url: '/wx/auth/login',
      method: 'POST',
      data
    })
  },
  //获取用户信息
  postUserInfo(data) {
    return request({
      url: '/jzshop/users/info',
      method: 'POST',
      data
    })
  },
  //获取验证码
  postSendSms(data) {
    return request({
      url: '/wx/auth/regCaptcha',
      method: 'POST',
      data
    })
  },
  //咪咕获取验证码
  postsendMGSms(data) {
    return request({
      url: '/wx/auth/sendMGSms',
      method: 'POST',
      data
    })
  },
  //退出登录
  logout() {
    return request({
      url: '/wx/auth/logout',
      method: 'POST',
      data
    })
  },
  //查询积分
  getUserAmount(data) {
    return request({
      url: '/wx/user/amount',
      method: 'GET',
      params: data
    })
  },
  //获取归属地
  getMobileAddress(data) {
    return request({
      url: '/wx/index/mobile',
      method: 'GET',
      params: data
    })
  },
  //修改本人密码
  getPaw(data) {
    return request({
      url: '/wx/user/alter/pwd',
      method: 'POST',
      data
    })
  },
  //修改下级密码
  getChilderPwd(data) {
    return request({
      url: '/wx/team/alter/children/pwd',
      method: 'POST',
      data
    })
  }

}