import request from '@/utils/request'
export default {
  //银行卡基础信息 
  getBankCardList(data) {
    return request({
      url: '/wx/withdraw/bank/index',
      method: 'GET',
      params: data
    })
  },
  //根据城市 银行 获取所有支行 
  getBankBranchList(data) {
    return request({
      url: '/wx/withdraw/bank/branche/list',
      method: 'GET',
      params: data
    })
  },
  //添加银行卡信息 
  postAddBank(data) {
    return request({
      url: '/wx/withdraw/user/bank/add',
      method: 'POST',
      data
    })
  },
  //获取用户拥有的银行卡列表  
  getUserBankList(data) {
    return request({
      url: '/wx/withdraw/user/bank/list',
      method: 'GET',
      params: data
    })
  },
  //获取用户可提现相关信息
  getUserProfile(data) {
    return request({
      url: '/wx/user/profile',
      method: 'GET',
      params: data
    })
  },
  //提交提现 
  postSubWithdraw(data) {
    return request({
      url: '/wx/withdraw/submit',
      method: 'POST',
      data
    })
  },
  //提现记录 
  getWithdrawHis(data) {
    return request({
      url: '/wx/withdraw/history',
      method: 'GET',
      params: data
    })
  },


}