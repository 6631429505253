import request from '@/utils/request'
export default {

  //地址列表
  getAddressList(data) {
    return request({
      url: '/wx/address/list',
      method: 'GET',
      params: data
    })
  },
  //地址详情
  postAddressDetail(data) {
    return request({
      url: '/wx/address/detail',
      method: 'GET',
      params: data
    })
  },

  //添加或者更新用户收货地址
  postAddressAdd(data) {
    return request({
      url: '/wx/address/save',
      method: 'POST',
      data
    })
  },
  //同步地址
  postAddressYD(data) {
    return request({
      url: '/wx/address/saveYD',
      method: 'POST',
      data
    })
  },
  //畅由号卡页面 地址提交
  postAddressCyAdd(data) {
    return request({
      url: '/wx/address/saveCY',
      method: 'POST',
      data
    })
  },
  //a删除地址u
  postAddressDel(data) {
    return request({
      url: '/wx/address/delete',
      method: 'POST',
      data
    })
  },
  //查询区域是否限制
  postAddressCheckAreaLimit(data) {
    return request({
      url: '/wx/address/checkAreaLimit',
      method: 'POST',
      data
    })
  }
}