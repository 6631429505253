import user from './model/user'
import order from './model/order'
import home from './model/home'
import person from './model/person'
import mall from './model/mall'
import address from './model/address'
import shop from './model/shop'
import team from './model/team';
import bank from './model/bank'
import exportExcel from './model/exportExcel'
import card from './model/card'
const API = Object.assign({},
  order,
  user,
  home,
  person,
  mall,
  address,
  shop,
  team,
  bank,
  exportExcel,
  card
)
export default API