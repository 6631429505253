export default {
  //获取地址栏参数
  getUrlParam(name) {
    // //unescape数可对通过 escape() 编码的字符串进行解码。
    // var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    // let url = window.location.href.split("#")[0];
    // let search = url.split("?")[1];
    // if (search) {
    //   var r = search.substr(0).match(reg);
    //   if (r !== null) return unescape(r[2]);
    //   return null;
    // } else return null;
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;

  },

  /**
   * 存储sessionStorage
   */
  setSessionStorage(name, content) {
    if (!name) {
      return
    }
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.sessionStorage.setItem(name, content)
  },

  /**
   * 获取sessionStorage
   */
  getSessionStorage(name) {
    if (!name) {
      return true
    } else {
      return window.sessionStorage.getItem(name)
    }
  },
  /**
   * 删除sessionStorage
   */
  removeSessionStorage(name) {
    if (!name) {
      return
    }
    window.sessionStorage.removeItem(name)
  },
  /**
   * 存储localStorage
   */
  setStore(name, content) {
    if (!name) {
      return
    }
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
  },

  /**
   * 获取localStorage
   */
  getStore(name) {
    if (!name) {
      return true
    } else {
      return window.localStorage.getItem(name)
    }
  },

  /**
   * 删除localStorage
   */
  removeStore(name) {
    if (!name) {
      return
    }
    window.localStorage.removeItem(name)
  },
  /**
   * 省份证/银行卡 密文显示
   */
  setIdCard(idNumber) {
    var getCard = String(idNumber)
      .replace(/\s/g, '')
      .replace(/^(\w{2})\d+(\w{2})$/, '$1 ****** $2')
    return getCard
  },
  /**
   * 日期装换
   */
  parseTime(time, cFormat) {
    if (arguments.length === 0) {
      return null
    }
    if (!time) return null
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if (('' + time).length === 10) time = parseInt(time) * 1000
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  },
  getNewDate(data) {
    const date = new Date(data)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    return {
      year,
      month,
      day
    }
  }
}