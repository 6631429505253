const state = {

  cachedViews: ['mall', 'mobile']
}
const getters = {

}
const mutations = {
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    }
  },
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  },
  ADD_CACHED: (state, name) => {

    if (state.cachedViews.includes(name)) return
    state.cachedViews.push(name)

  },
  DEL_CAHED: (state, name) => {
    const index = state.cachedViews.indexOf(name)
    index > -1 && state.cachedViews.splice(index, 1)
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,

}