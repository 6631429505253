/**
 * 订单状态
 */
export function getOrderStatus(val) {
  let map = {
    101: "订单生成，未支付",
    102: "下单后未支付,用户取消",
    103: "下单后未支付,超时系统自动取消",
    201: " 支付完成，商家未发货",
    202: "订单生产，已付款未发货，但是退款取消",
    203: "管理员执行退款操作，确认退款成功",
    301: "商家发货，用户未确认",
    401: "用户确认收货",
    402: "用户没有确认收货,超过一定时间，系统自动确认收货",
  };
  return map[val] || "";
}
/**
 * 提现状态
 */
export function getWithdrawState(val) {
  // let map = {
  //   1: '待审核',
  //   2: '审核成功',
  //   3: "已完成",
  //   4: '管理员已驳回'
  // }
  let map = {
    1: '待审核',
    2: "已完成",
    3: '管理员已驳回'
  }
  return map[val] || ''
}
/**
 * 收支明细类型
 * 0 订单收入结算后 1 提现 2 平台扣款 3 冻结金额 4 后台修改渠道信息 5 订单收入结算前 6 平台撤单 7 平台罚款 8 平台取消撤单 9 平台取消罚款
 */
export function businessType(val) {
  let map = {
    // 0: '做单收入',
    // 1: '提现记录',
    // 2: '平台扣款',
    // 3: '冻结金额',
    // 4: '后台修改渠道信息',
    // 5: '订单收入结算前',
    // 6: '平台撤单',
    // 7: '平台罚款',
    // 8: '平台取消撤单',
    // 9: '平台取消罚款'
    1: '平台罚款',
    2: '平台撤单',
    3: '平台调账',
    4: '做单收入'
  }
  return map[val] || ''
}

export function htmlEncode(html) {
  /*1.用浏览器内部转换器实现html转码*/

  //1.首先动态创建一个容器标签元素，如DIV
  var temp = document.createElement("div");
  //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
  (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
  //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
  var output = temp.innerHTML;
  temp = null;
  return output;

};
//号卡明细
export function HkOrderState(val) {
  let map = {
    1: '受理成功',
    2: '受理失败',
    3: '已激活',
    4: '待配送',
    5: '配送中'
  }
  return map[val] || ''
}